import axios from '../../axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default {
  mixins: [
  ],
  data() {
    return {
      notify: useQuasar(),
      projectId: 0,
      quantityItems: [],
      quantityUnitTypes: [],
      quantityProjectItem: {},
      quantityProjectItems: [],
      quantityprojectItemFields: [
        { name: 'id', label: 'Id', field: 'id' },
        { name: 'quantityItem.description', label: 'Name', field: row => row.quantityItem.description },
        { name: 'planQuantity', label: 'Plan Qty.', field: 'planQuantity' },
        { name: 'unitPrice', label: 'Unit Price', field: 'unitPrice' },
        { name: 'unitType.unit', label: 'Unit', field: row => row.unitType.unit },
        { name: 'quantityUsed', label: 'Used Qty.', field: 'quantityUsed' },
        { name: 'percentCompleteDisplay', label: '% Complete', field: 'percentCompleteDisplay' },
        { name: 'percentofCostDisplay', label: '% Cost', field: 'percentofCostDisplay' },
        { name: 'costDisplay', label: 'Cost', field: 'costDisplay' },
      ],
      columnsProjectItems: [
        { name: 'quantityItem.description', align: 'center', label: 'Item', field: row => row.quantityItem.description },
        { name: 'planQuantity', label: 'Plan Quantity', field: 'planQuantity'},
        { name: 'unitPrice', label: 'Unit Proce', field: 'unitPrice' },
        { name: 'unitType.unit', align: 'center', label: 'Unit', field: row => row.unitType.unit },
      ],
      quantityItemsUsageModal: '',
      quantityItemsUsage: [],
      quantityItemUsage: {},
      quantityItemsUsageFields: [
        { name: 'id', label: 'Id', field: 'id' },
        { name: 'dateDisplay', label: 'Date', field: 'dateDisplay' },
        { name: 'location', label: 'Location', field: 'location' },
        { name: 'remarks', label: 'Remarks', field: 'remarks' },
        { name: 'quantity', label: 'Quantity', field: 'quantity' },
        { name: 'quantityItemUsageType.name', label: 'M/P/E', field: row => row.quantityItemUsageType.name },
      ],
      quantityUsageTypes: [],
    }
  },
  methods: {
    async getQuantityUnitTypes() {
      await axios
        .get('/api/Gencon/QuantityUnitTypes')
        .then(response => {
          response.data.forEach(element => this.quantityUnitTypes.push({ value: element.id, label: element.unit }))
        })
    },
    async getQuantityItems() {
      await axios
        .get('/api/Gencon/QuantityItems')
        .then(response => { this.quantityItems = response.data })
    },
    async getProjectQuantityItems(projectId) {
      await axios
        .get(`/api/Gencon/QuantityProjectItems/ProjectId/${projectId}`)
        .then(response => { this.quantityProjectItems = response.data })
    },
    async postProjectQuantityItem(quantityId) {
      this.quantityProjectItem.projectId = this.projectId
      this.quantityProjectItem.quantityItemId = quantityId
      await axios
        .post('/api/Gencon/QuantityProjectItems', this.quantityProjectItem)
        .then(response => {
          console.log(response.data)
          this.getProjectQuantityItems(this.projectId)
          this.notify.notify({
            message: 'Saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
    },
    async getQuantityProjectItems(projectId) {
      await axios
        .get(`/api/Gencon/QuantityProjectItems/ProjectId/${projectId}`)
        .then(response => { this.quantityProjectItems = response.data })
    },
    async getQuantityProjectItem(id) {
      await axios
        .get(`/api/Gencon/QuantityProjectItems/Id/${id}`)
        .then(response => { this.quantityProjectItem = response.data })
    },
    async getQuantityItemsUsage(projectItemId) {
      await axios
        .get(`/api/Gencon/QuantityItemsUsage/ProjectItemId/${projectItemId}`)
        .then(response => { this.quantityItemsUsage = response.data })
    },
    async getQuantityItemUsage(id) {
      await axios
        .get(`/api/Gencon/QuantityItemsUsage/Id/${id}`)
        .then(response => { this.quantityItemUsage = response.data })
    },
    async postQuantityItemUsage() {
      this.quantityItemUsage.quantityItemProjectId = this.quantityProjectItem.id
      await axios
        .post('/api/Gencon/QuantityItemsUsage', this.quantityItemUsage)
        .then(response => {
          this.quantityItemUsage = response.data
          this.notify.notify({
            message: 'Saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      this.getQuantityItemsUsage(this.quantityItemUsage.quantityItemProjectId)
      this.getQuantityProjectItems(this.projectId)
    },
    async getQuantityUsageTypes() {
      await axios
        .get('/api/Gencon/QuantityUsageTypes')
        .then(response => {
          response.data.forEach(element => this.quantityUsageTypes.push({ value: element.id, label: element.name }))
        })
    },
    async deleteQuantityItemUsage() {
      await axios
        .delete(`/api/Gencon/QuantityItemsUsage/Id/${this.quantityItemUsage.id}`)
        .then(response => {
          console.log(response)
          this.notify.notify({
            message: 'Deleted',
            color: 'green'
          })
        })
      this.getQuantityItemsUsage(this.quantityItemUsage.quantityItemProjectId)
    },
    async setupQuantityItemsUsage(projectItemId) {
      this.getQuantityItemsUsage(projectItemId)
      this.getQuantityProjectItem(projectItemId)
    },
  },
}
