<template>
    <div class="q-pa-md">
        <q-card class="my-card">
          <q-card-section>
              <div class="text-h6">{{ project.projectNumber }} - {{ project.projectName }}</div>
              <div class="text-subtitle2">{{ project.projectLocation }}</div>
          </q-card-section>
          <q-separator />
          <q-tabs
            v-model="tab"
            dense
            align="left"
            class="bg-primary text-white shadow-2"
            :breakpoint="0"
          >
            <q-tab name="overview" label="Overview"/>
            <q-tab name="vactests" label="Vac Tests"/>
            <q-tab name="itemsusage" label="Item Usage"/>
            <q-tab name="itemssetup" label="Item Setup"/>
          </q-tabs>
        </q-card>
        <div class="q-mt-md">

          <div v-if="tab === 'overview'" class="q-pa-sm">
          </div>

          <VaccumTests v-if="tab === 'vactests'"/>
          <ItemsSetup v-if="tab === 'itemssetup'"/>
          <ItemsUsage v-if="tab === 'itemsusage'"/>
        </div>
    </div>
</template>

<script>
import axios from '../axios'
import ItemsSetup from '../components/gencon/ItemsSetup.vue'
import ItemsUsage from '../components/gencon/ItemsUsage.vue'
import VaccumTests from '../components/gencon/VaccumTests.vue'

export default {
  components: {
    ItemsSetup,
    ItemsUsage,
    VaccumTests,
  },
  mixins: [
  ],
  data() {
    return {
      projects: [],
      project: {},
      projectId: this.$route.params.id,
      tab: 'overview',
    }
  },
  created() {
    axios.get(`/api/Projects/Id/${this.projectId}`)
      .then(response => {
        this.project = response.data
      })
  },
  methods: {
  }
}
</script>