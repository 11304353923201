<template>
  <div>
     <div class="row q-col-gutter-x-md q-col-gutter-y-md">
        <div class="col-12">
            <q-table
              title="Project Items"
              :dense="true"
              @row-click="listItemsUsage"
              :rows="quantityProjectItems"
              :columns="quantityprojectItemFields"
            />
        </div>
        <div v-if="quantityProjectItem.quantityItem != null" class="col-12">
            <q-table
              @row-click="showUsageDialogEv"
              :dense="true"
              :title="quantityProjectItem.quantityItem.description"
              :rows="quantityItemsUsage"
              :columns="quantityItemsUsageFields"
            >
            <template v-slot:top-right>
              <q-input borderless dense debounce="300" v-model="filter" placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <div class="q-pl-md">
                <q-btn color="green" text-color="white" label="Add" @click="showUsageDialog(0)"/>
              </div>
            </template>
            </q-table>
        </div>
    </div>
    <q-dialog
      v-model="itemUsageDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postQuantityItemUsage()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="quantityItemUsage.dateDisplay"
                  :dense="true"
                  label="Date"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="quantityItemUsage.location"
                  :dense="true"
                  label="Location"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="quantityItemUsage.remarks"
                  :dense="true"
                  label="Remarks"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="quantityItemUsage.quantity"
                  :dense="true"
                  label="Quantity"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select label="Type" outlined emit-value map-options :dense="true" v-model="quantityItemUsage.quantityItemUsageTypeId" :options="quantityUsageTypes" />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="deleteQuantityItemUsage">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import quantitiesMixin from './quantitiesMixin'

export default {
  mixins: [
    quantitiesMixin,
  ],
  data() {
    return {
      projectId: this.$route.params.id,
      itemUsageDialog: false,
      submitDisable: false,
    }
  },
  created() {
    this.getProjectQuantityItems(this.projectId)
    this.getQuantityUsageTypes()
  },
  methods: {
    async listItemsUsage(evt, row) {
      this.setupQuantityItemsUsage(row.id)
    },
    async showUsageDialogEv(evt, row) {
      await this.getQuantityItemUsage(row.id)
      this.itemUsageDialog = true
    },
    async showUsageDialog(id) {
      await this.getQuantityItemUsage(id)
      this.itemUsageDialog = true
    },
  },
}
</script>
