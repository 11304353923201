<template>
  <div>
     <div class="row q-col-gutter-x-md q-col-gutter-y-md">
        <div class="col-12">
            <q-table
              @row-click="showVacTestDialogEv"
              :dense="true"
              title="Vaccum Test"
              :rows="vaccumTests"
              :columns="columnsVaccumTests"
              :pagination="{ rowsPerPage: 15 }"
              :filter="filter"
            >
            <template v-slot:top-right>
              <q-input borderless dense debounce="300" v-model="filter" placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <div class="q-pl-md">
                <q-btn color="green" text-color="white" label="Add" @click="showVacTestDialog(0)"/>
              </div>
            </template>
            </q-table>
        </div>
    </div>
    <q-dialog
      v-model="vacTestDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postVaccumTest()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="vaccumTest.dateDisplay"
                  :dense="true"
                  label="Date"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="vaccumTest.location"
                  :dense="true"
                  label="Location"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="vaccumTest.startPressure"
                  :dense="true"
                  label="Start Hg"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="vaccumTest.endPressure"
                  :dense="true"
                  label="End Hg"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="vaccumTest.testTime"
                  :dense="true"
                  label="Test Time"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="vaccumTest.notes"
                  :dense="true"
                  label="Notes"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select label="Type" outlined emit-value map-options :dense="true" v-model="vaccumTest.passed" :options="passed" />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" :disable="submitDisable" :loading="submitDisable" v-touch-hold:3000.mouse="deleteVaccumTest">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from '../../axios'
import { useQuasar } from 'quasar'

export default {
  mixins: [
  ],
  data() {
    return {
      notify: useQuasar(),
      filter: '',
      projectId: this.$route.params.id,
      passed: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ],
      vaccumTest: {},
      vaccumTests: [],
      vacTestDialog: false,
      submitDisable: false,
      columnsVaccumTests: [
        { name: 'id', align: 'center', label: 'Id', field: 'id' },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay' },
        { name: 'location', align: 'center', label: 'Location', field: 'location'},
        { name: 'startPressure', align: 'center', label: 'Start Hg', field: 'startPressure' },
        { name: 'endPressure', align: 'center', label: 'End Hg', field: 'endPressure' },
        { name: 'testTime', align: 'center', label: 'Test Time', field: 'testTime' },
        { name: 'passed', align: 'center', label: 'Passed', field: 'passed' },
        { name: 'notes', align: 'center', label: 'Notes', field: 'notes' },
      ],
    }
  },
  created() {
    this.getVaccumTests(this.projectId)
  },
  methods: {
    async getVaccumTest(id) {
        await axios
            .get(`/api/Gencon/VaccumTests/Id/${id}`)
            .then(res => {
                this.vaccumTest = res.data
            })
            .catch(error => {
                this.notify.notify({
                    message: error.response.data.message,
                    color: 'red'
                })
            })
    },
    async getVaccumTests(projectId) {
        await axios
            .get(`/api/Gencon/VaccumTests/ProjectId/${projectId}`)
            .then(res => {
                this.vaccumTests = res.data
            })
            .catch(error => {
                this.notify.notify({
                    message: error.response.data.message,
                    color: 'red'
                })
            })
    },
    async postVaccumTest() {
        this.submitDisable = true
        this.vaccumTest.projectId = this.projectId
        await axios
            .post('/api/Gencon/VaccumTests', this.vaccumTest)
            .then(res => {
                this.vaccumTest = res.data
                this.notify.notify({
                    message: 'Saved',
                    color: 'green'
                })
            })
            .catch(error => {
                this.notify.notify({
                    message: error.response.data.message,
                    color: 'red'
                })
            })
        this.submitDisable = false
        this.getVaccumTests(this.projectId)
    },
    async deleteVaccumTest() {
        /* eslint-disable no-unused-vars */
        await axios
            .delete(`/api/Gencon/VaccumTests/Id/${this.vaccumTest.id}`)
            .then(res => {
                this.notify.notify({
                    message: 'Deleted',
                    color: 'green'
                })
            })
            .catch(error => {
                this.notify.notify({
                    message: error.response.data.message,
                    color: 'red'
                })
            })
        /* eslint-enable no-unused-vars */
        this.vacTestDialog = false
        this.getVaccumTests(this.projectId)
    },
    async showVacTestDialogEv(evt, row) {
        await this.getVaccumTest(row.id)
        this.vacTestDialog = true
    },
    async showVacTestDialog(id) {
        await this.getVaccumTest(id)
        this.vacTestDialog = true
    },
  },
}
</script>
