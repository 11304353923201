<template>
  <div>
    <div class="row q-col-gutter-x-md q-col-gutter-y-md">
      <div class="col-4">
        <q-scroll-area style="height: 400px;">
          <q-list bordered separator>
            <q-item v-for="item in quantityItems" :key="item.id">
              <q-item-section>{{ item.description }}</q-item-section>
              <q-item-section avatar>
                <q-btn color="primary" text-color="white" label="Add" @click="postProjectQuantityItem(item.id)"/>
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>
      </div>
      <div class="col-8">
          <q-table
            title="Project Items"
            @row-click="showProjectItem"
            :rows="quantityProjectItems"
            :columns="columnsProjectItems"
            row-key="name"
          />
      </div>
    </div>
    <q-dialog
      v-model="projectItemDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <div class="text-h6">{{ quantityProjectItem.quantityItem.description }}</div>
          <q-form
            @submit="postProjectQuantityItem(0)"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="quantityProjectItem.planQuantity"
                  :dense="true"
                  label="Plan Quantity"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="quantityProjectItem.unitPrice"
                  :dense="true"
                  label="Unit Price"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select label="Status" outlined emit-value map-options :dense="true" v-model="quantityProjectItem.unitTypeId" :options="quantityUnitTypes" />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="entryDelete">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import quantitiesMixin from './quantitiesMixin'

export default {
    mixins: [
        quantitiesMixin,
    ],
    data() {
        return {
            projectId: this.$route.params.id,
            projectItemDialog: false,
            submitDisable: false,
        }
    },
    created() {
        this.getQuantityItems()
        this.getProjectQuantityItems(this.projectId)
        this.getQuantityUnitTypes()
    },
    methods: {
        async showProjectItem(evt, row) {
            this.submitDisable = true
            await this.getQuantityProjectItem(row.id)
            this.projectItemDialog = true
            this.submitDisable = false
        },
    },
}
</script>
